var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('tarjeta-datos',{attrs:{"header":"","titulo":"Datos Factura","datos":[
					{ nombre: 'Canal', valor: _vm.datos.Canal },
					{ nombre: 'Pago factura', valor: _vm.parseDate(_vm.datos.FechaFactura) },
					{ nombre: 'Cuenta pago', valor: _vm.datos.IBAN } ]}},[_c('v-divider'),_c('aux-input',{attrs:{"title":"Estado"}},[_c('status-chip',{attrs:{"small":"","value":_vm.datos.estado}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('tarjeta-datos',{staticClass:"flex-grow-1",attrs:{"titulo":"Datos documento","header":"","datos":[
					{
						nombre: _vm.datos.RazonSocialTitular ? 'Razón Social' : 'Nombre cliente',
						valor: [_vm.datos.NombreTitular, _vm.datos.Apellido1Titular, _vm.datos.Apellido2Titular, _vm.datos.RazonSocialTitular].filter(function (x) { return !!x; }).join(' '),
					},
					{ nombre: 'Nif/Cif', valor: _vm.datos.Identificador },
					{ nombre: 'Fecha Factura', valor: _vm.parseDate(_vm.datos.FechaFactura) },
					{ nombre: 'Número Documento', valor: [_vm.datos.SerieFactura, _vm.datos.NumeroFactura].join(' ') } ]}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('tarjeta-datos',{staticClass:"flex-grow-1",attrs:{"titulo":"Datos importe","header":"","datos":[
					// { nombre: 'Base Imponible', valor: datos.ImporteBase + ' €' },
					// { nombre: `Impuestos (${datos.PorcentajeImpuesto * 1}%)`, valor: datos.ImporteImpuesto + ' €' },
					{ nombre: 'Total Factura', valor: _vm.datos.ImporteTotal + ' €' } ]}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('tarjeta-datos',{staticClass:"flex-grow-1",attrs:{"titulo":"Datos envío","header":"","datos":[
					{ nombre: 'Dirección', valor: [_vm.datos.NumeroEnvio, _vm.datos.CalleEnvio].join(' ') },
					{ nombre: 'Población', valor: _vm.datos.CiudadEnvio },
					{ nombre: 'Provincia', valor: _vm.datos.ProvinciaEnvio },
					{ nombre: 'Cód.Postal', valor: _vm.datos.CPEnvio } ]}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{attrs:{"max-width":"1400px","width":"100%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
				var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex mb-2"},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","outlined":""}},'v-btn',attrs,false),on),[_vm._v("Ver el contrato")])],1)]}}])},[_c('DetallesContrato',{staticClass:"pa-0",attrs:{"_codigoContrato":_vm.datos.CodigoContrato,"_idContrato":_vm.datos.IdContrato}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('tarjeta-datos',{staticClass:"pa-0 w-100",attrs:{"titulo":"Contenido Factura","header":""}},[_c('v-data-table',{attrs:{"fixed-header":"","items":_vm.datos.lineas,"headers":_vm.headers,"calculate-widths":true,"loading":_vm.loading,"item-key":"IdCarteraCobroVentaLinea","single-select":false,"footer-props":{
						showFirstLastPage: true,
						showCurrentPage: true,
						itemsPerPageOptions: [10, 25, 50],
					},"checkbox-color":"secondary"},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
					var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.datos.lineas},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.FechaCreacion",fn:function(ref){
					var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaCreacion, false, true, true))}})]}},{key:"item.PorcentajeImpuesto",fn:function(ref){
					var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.PorcentajeImpuesto * 1 + '%')}})]}},{key:"item.ImporteTotal",fn:function(ref){
					var item = ref.item;
return [_c('v-chip',{attrs:{"label":""},domProps:{"textContent":_vm._s(parseFloat(item.ImporteTotal).toFixed(2) + '€')}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }