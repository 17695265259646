<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" md="6" lg="3">
				<tarjeta-datos
					header
					titulo="Datos Factura"
					:datos="[
						{ nombre: 'Canal', valor: datos.Canal },
						{ nombre: 'Pago factura', valor: parseDate(datos.FechaFactura) },
						{ nombre: 'Cuenta pago', valor: datos.IBAN },
					]"
				>
				<v-divider />
				<aux-input title="Estado">
					<status-chip small :value="datos.estado" />
				</aux-input>
				</tarjeta-datos>
			</v-col>
			<v-col cols="12" md="6" lg="3">
				<tarjeta-datos
					class="flex-grow-1"
					titulo="Datos documento"
					header
					:datos="[
						{
							nombre: datos.RazonSocialTitular ? 'Razón Social' : 'Nombre cliente',
							valor: [datos.NombreTitular, datos.Apellido1Titular, datos.Apellido2Titular, datos.RazonSocialTitular].filter(x => !!x).join(' '),
						},
						{ nombre: 'Nif/Cif', valor: datos.Identificador },
						{ nombre: 'Fecha Factura', valor: parseDate(datos.FechaFactura) },
						{ nombre: 'Número Documento', valor: [datos.SerieFactura, datos.NumeroFactura].join(' ') },
					]"
				></tarjeta-datos>
			</v-col>
			<v-col cols="12" md="6" lg="3">
				<tarjeta-datos
					class="flex-grow-1"
					titulo="Datos importe"
					header
					:datos="[
						// { nombre: 'Base Imponible', valor: datos.ImporteBase + ' €' },
						// { nombre: `Impuestos (${datos.PorcentajeImpuesto * 1}%)`, valor: datos.ImporteImpuesto + ' €' },
						{ nombre: 'Total Factura', valor: datos.ImporteTotal + ' €' },
					]"
				></tarjeta-datos>
			</v-col>
			<v-col cols="12" md="6" lg="3">
				<tarjeta-datos
					class="flex-grow-1"
					titulo="Datos envío"
					header
					:datos="[
						{ nombre: 'Dirección', valor: [datos.NumeroEnvio, datos.CalleEnvio].join(' ') },
						{ nombre: 'Población', valor: datos.CiudadEnvio },
						{ nombre: 'Provincia', valor: datos.ProvinciaEnvio },
						{ nombre: 'Cód.Postal', valor: datos.CPEnvio },
					]"
				></tarjeta-datos>
			</v-col>

			<v-col cols="12">
				<v-dialog max-width="1400px" width="100%">
					<template v-slot:activator="{ on, attrs }">
						<div class="d-flex mb-2">
							<v-btn class color="primary" outlined v-on="on" v-bind="attrs">Ver el contrato</v-btn>
						</div>
					</template>
					<DetallesContrato
						:_codigoContrato="datos.CodigoContrato"
						:_idContrato="datos.IdContrato"
						class="pa-0"
					/>
				</v-dialog>
			</v-col>

			<v-col cols="12">
				<tarjeta-datos titulo="Contenido Factura" header class="pa-0 w-100">
					<v-data-table
						fixed-header
						:items="datos.lineas"
						:headers="headers"
						:calculate-widths="true"
						:loading="loading"
						item-key="IdCarteraCobroVentaLinea"
						:single-select="false"
						:footer-props="{
							showFirstLastPage: true,
							showCurrentPage: true,
							itemsPerPageOptions: [10, 25, 50],
						}"
						checkbox-color="secondary"
					>
						<template v-slot:body.prepend="{ headers }">
							<table-filters :headers="headers" :items="datos.lineas" v-model="inlineFilters"></table-filters>
						</template>

						<template v-slot:item.FechaCreacion="{ item }">
							<span v-text="parseDate(item.FechaCreacion, false, true, true)"></span>
						</template>

						<template v-slot:item.PorcentajeImpuesto="{ item }">
							<span v-text="item.PorcentajeImpuesto * 1 + '%'"></span>
						</template>

						<template v-slot:item.ImporteTotal="{ item }">
							<v-chip label v-text="parseFloat(item.ImporteTotal).toFixed(2) + '€'"></v-chip>
						</template>
					</v-data-table>
				</tarjeta-datos>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { parseDate, perColumnFilter, downloadFile } from '@/utils/index.js'

export default {
	components: {
		TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
		TableFilters: () => import("@/components/TableFilters.vue"),
		StatusChip: () => import("@/components/StatusChip.vue"),
		AuxInput: () => import("@/components/AuxInput.vue"),
		DetallesContrato: () => import("@/modules/contratos/views/DetallesContrato.vue"),
	},
	props: {
		IdCarteraCobro: Number | String,
	},
	data() {
		return {
			loading: false,
			items: [],
			inlineFilters: {},
			headers: [
				{ text: "Línea", value: "Linea" },
				{ text: "Fecha", value: "FechaCreacion", dataType: 'date' },
				{ text: "Descripción", value: "Descripcion" },
				{ text: "Importe Base", value: "ImporteBase" },
				{ text: "% Impuestos", value: "PorcentajeImpuesto" },
				{ text: "Importe Total", value: "ImporteTotal" },
			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),
			datos: {}
		};
	},
	mounted() {
		this.getFacturaDatos();
	},
	methods: {
		parseDate,
		getFacturaDatos() {
			axios({
				method: "GET",
				url: `${process.env.VUE_APP_OUR_API_URL}/facturacion.php`,
				params: {
					token: this.$store.getters.getJwtEmpresa,
					IdCarteraCobro: this.IdCarteraCobro
				}
			}).then(res => {
				const getEstado = (value) => {
					switch (Number(value)) {
						case -2: return "Gesico";
						case -3: return "Pérdidas";
						case 0: return 'Sin Especificar';
						case 1: return 'Pendiente';
						case 2: return 'Cobrado';
						case 3: return 'Impagado';
						case 4: return 'Remesado';
						case 5: return 'Carta Aviso';
						case 6: return 'Carta Corte';
						case 7: return 'Cortado';
						case 8: return 'Cobrado norma 57';
						case 9: return 'Cancelado por abono';
						case 10: return 'Carta Corte 1';
						case 11: return 'Carta Deuda';
						case 12: return 'Fraccionado';
					}
				}
				this.datos = { ...res.data, estado: getEstado( res.data.CodigoSituacionCobro ) }
			}).catch(err => {
				console.error(err.response)
			})
		},
		verPDFfactura(idDocumento) {
			let str = this.$store.getters.getDatosEmpresa;
			const { IdUsuario } = str.iss;
			const IdEmpresa = this.$store.getters.getIdEmpresa;
			axios({
				method: "get",
				url: `${process.env.VUE_APP_API_URL}/FacturaVentaCabecera/FacturaPreviewPDF/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Documento/${idDocumento}`,
				responseType: "blob",
			})
				.then((res) => {
					let pdf = new File(
						[res.data],
						`factura_${new Date().getTime()}.pdf`,
						{
							type: "application/pdf",
						}
					);
					downloadFile(pdf, true);
				})
				.catch((err) => {
					this.$root.$emit("snack", "Ha ocurrido un error");
				});
		},
	}
};
</script>

<style>
</style>